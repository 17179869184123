import React, { useContext } from 'react';
import { MenuContext } from '../../../context'

import NewsArticle from '../../../components/news-article/news-article.component'

import { LATEST_RATE_SHEET } from '../../../data/latest-rate-sheet.data'

const Article = () => {
  const menuContext = useContext(MenuContext);

  return (
    <NewsArticle>
      <h1 className='page-title'>Changes to our mortgage products</h1>
      <p>With effect from Tuesday 12th January 2021, we will be making the following changes to our mortgage product range:</p>
      <p><strong>Summary of changes</strong></p>
      <ul>
        <li>2 Year Fixed Standard at 60%, 70% and 75% have reduced</li>
        <li>2 Year Fixed Standard at 90% has increased</li>
        <li>2 Year Fixed Fee Saver at 60% and 80% have reduced</li>
        <li>2 Year Fixed Fee Saver at 90% and 95% have increased</li>
        <li>5 Year Fixed Standard at 60%, 70%, 75% and 80% have reduced</li>
        <li>5 Year Fixed Standard at 90% has increased</li>
        <li>5 Year Fixed Fee Saver at 60%, 70%, 75% and 80% have reduced</li>
        <li>5 Year Fixed Fee Saver at 90% and 95% have increased</li>
        <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75% and 80% have reduced</li>
        <li>5 Year Fixed Premier Exclusive at 90% has increased</li>
        <li>2 Year Term Tracker at 90% has increased</li>
      </ul>
      <p>In addition to the above, all of our fixed rate end dates will be extended as below:</p>
      <ul>
        <li>2 year fixed rates – 31st May 2023</li>
        <li>5 year fixed rates – 31st May 2026</li>
      </ul>
      <p>We have reintroduced our 90% LTV mortgage range for all buyer types and channels. However, our 95% LTV range is still only available for existing HSBC mortgage customers switching rates and not new business.</p>
      <p>You can browse the range of HSBC mortgages using our <a href="/products/product-finder.html" inert={menuContext.isMenuOpen ? "true" : null}>product finder</a> or by downloading our <a title="This PDF opens in a new window" target="_blank" href={LATEST_RATE_SHEET.href} inert={menuContext.isMenuOpen ? "true" : null}>latest rate sheet<span className="accessible">&nbsp;This PDF opens in a new window</span></a>.</p>
    </NewsArticle>
  )
}

export default Article
